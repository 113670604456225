import { useReducer } from 'react';

export default function useContextReducer() {
    const INITIAL_STATE = { darkMode: true };

    const themeReducer = (state, action) => {
        switch (action.type) {
            case 'TOGGLE':
                return { darkMode: !state.darkMode };
            default:
                return state;
        }
    };

    const [state, dispatch] = useReducer(themeReducer, INITIAL_STATE);

    return { state, dispatch };
}
