import { useContext } from 'react';
import About from '../components/about/About';
import Contact from '../components/contact/Contact';
import Intro from '../components/intro/Intro';
import ProjectList from '../components/projectList/ProjectList';
import Toggle from '../components/toggle/Toggle';
import { ThemeContext } from '../context';

export default function useApp() {
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    const appStyles = { backgroundColor: darkMode ? '#222' : 'white', color: darkMode && 'white' };
    return {
        About,
        Contact,
        Intro,
        ProjectList,
        Toggle,
        appStyles,
        darkMode
    };
}
