import useToggle from '../../hooks/useToggle';
import './toggle.css';

const Toggle = () => {
    const { Sun, Moon, theme, handleClick } = useToggle();
    return (
        <div className='t'>
            <img src={Sun} alt='' className='t-icon' />
            <img src={Moon} alt='' className='t-icon' />
            <div className='t-button' onClick={handleClick} style={{ left: theme.state.darkMode ? 0 : 25 }}></div>
        </div>
    );
};

export default Toggle;
