import Sun from '../img/sun.png';
import Moon from '../img/moon.png';
import { useContext } from 'react';
import { ThemeContext } from '../context';

export default function useToggle() {
    const theme = useContext(ThemeContext);
    const handleClick = () => {
        theme.dispatch({ type: 'TOGGLE' });
    };
    return {
        Sun,
        Moon,
        theme,
        handleClick,
    };
}
