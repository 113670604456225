import { useContext, useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { ThemeContext } from '../context';
import { emailJsConfig } from '../data';
import Phone from '../img/phone.png';
import Email from '../img/email.png';
import Linkedin from '../img/linkedin.png';

export default function useContact() {
    const formRef = useRef();
    const buttonRef = useRef();

    const [done, setDone] = useState(false);
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;
    const phoneNumber = '+8801521108069';
    const emailAddress = 'parvezrobi@yahoo.com';
    const LinkedinAddress = 'https://www.linkedin.com/in/robi-parvez';
    const linkedinStyles = { cursor: 'pointer', textDecoration: 'none', color: !darkMode ? '#222' : 'white' };

    const handleSubmit = e => {
        e.preventDefault();
        buttonRef.current.disabled = true;

        let { serviceId, templateId, userId } = emailJsConfig;
        emailjs.sendForm(serviceId, templateId, formRef.current, userId).then(
            result => {
                // console.log(result.text);
                buttonRef.current.disabled = false;
                setDone(true);
            },
            error => {
                buttonRef.current.disabled = false;
                console.log(error.text);
            }
        );
    };

    return {
        Phone,
        Email,
        Linkedin,
        formRef,
        buttonRef,
        done,
        darkMode,
        phoneNumber,
        emailAddress,
        LinkedinAddress,
        linkedinStyles,
        handleSubmit
    };
}
