import './intro.css';
import Me from '../../img/me.png';
import Svg from '../partials/svg';

const Intro = () => {
    return (
        <div className='i'>
            <div className='i-left'>
                <div className='i-left-wrapper'>
                    <h2 className='i-intro'>Hello, My name is</h2>
                    <h1 className='i-name'>Robi Parvez</h1>
                    <div className='i-title'>
                        <div className='i-title-wrapper'>
                            <div className='i-title-item'>Software Engineer</div>
                            <div className='i-title-item'>Full Stack Web Developer</div>
                            <div className='i-title-item'>Research Enthusiast</div>
                            <div className='i-title-item'>Team Leader</div>
                        </div>
                    </div>
                    <p className='i-desc'>I design and develop services for customers of all sizes, specializing in creating stylish, modern websites and web services.</p>
                </div>
                <Svg />
            </div>
            <div className='i-right'>
                <div className='i-bg'></div>
                <img src={Me} alt='' className='i-img' />
            </div>
        </div>
    );
};

export default Intro;
