import './projectList.css';
import Project from '../project/Project';
import { projects } from '../../data';

const ProjectList = () => {
    return (
        <div className='pl'>
            <div className='pl-texts'>
                <h1 className='pl-title'>Create & inspire. It's Robi.</h1>
                <p className='pl-desc'>I have worked on a wide range of projects with the primary focus being Laravel, Vue.Js, React.Js & Node.Js</p>
            </div>
            <div className='pl-list'>
                {projects.map(item => (
                    <Project key={item.id} img={item.img} link={item.link} />
                ))}
            </div>
        </div>
    );
};

export default ProjectList;
