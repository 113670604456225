import './contact.css';
import useContact from '../../hooks/useContact';

const Contact = () => {
    const { Phone, Email, Linkedin, formRef, buttonRef, done, darkMode, phoneNumber, emailAddress, LinkedinAddress, linkedinStyles, handleSubmit } = useContact();

    return (
        <div className='c'>
            <div className='c-bg'></div>
            <div className='c-wrapper'>
                <div className='c-left'>
                    <h1 className='c-title'>Let's discuss your project</h1>
                    <div className='c-info'>
                        <div className='c-info-item'>
                            <img src={Phone} alt='' className='c-icon' />
                            {phoneNumber}
                        </div>
                        <div className='c-info-item'>
                            <img className='c-icon' src={Email} alt='' />
                            {emailAddress}
                        </div>
                        <div className='c-info-item'>
                            <img className='c-icon' src={Linkedin} alt='' />
                            <a href='https://www.linkedin.com/in/robi-parvez' style={linkedinStyles}>
                                {LinkedinAddress}
                            </a>
                        </div>
                    </div>
                </div>
                <div className='c-right'>
                    <p className='c-desc'>
                        <b>What’s your story?</b> Get in touch. Always available for freelancing if the right project comes along.
                    </p>
                    <form ref={formRef} onSubmit={handleSubmit}>
                        <input style={{ backgroundColor: darkMode && '#333' }} type='text' required placeholder='Name' name='user_name' />
                        <input style={{ backgroundColor: darkMode && '#333' }} type='text' required placeholder='Subject' name='user_subject' />
                        <input style={{ backgroundColor: darkMode && '#333' }} type='text' required placeholder='Email' name='user_email' />
                        <textarea style={{ backgroundColor: darkMode && '#333' }} rows='5' required placeholder='Message' name='message' />
                        <button ref={buttonRef}>Submit</button>
                        {done && <span className='thanks-text'>Thank you...</span>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Contact;
