import ParticlesComponent from './components/particles/particles';
import useApp from './hooks/useApp';

const App = () => {
    const { appStyles, Toggle, Intro, About, ProjectList, Contact } = useApp();

    return (
        <div style={appStyles}>
            <ParticlesComponent />
            <Toggle />
            <Intro />
            <About />
            <ProjectList />
            <Contact />
        </div>
    );
};

export default App;
