import './about.css';
import TestDome from '../../certifications/TestDome.jfif';
import Bdjobs from '../../certifications/Bdjobs.jfif';

const About = () => {
    const aboutSubText = 'A willing learning and a hard worker capable to adapt in any working environment';
    const aboutDescText = 'Programming has been a passion for me since childhood. I am an expert in PHP Laravel framework with adequate knowledge in database technologies (MySQL, MSSQL) and JavaScript technologies (Vanilla Javascript, jQuery, Vue.js, React.js, TypeScript).';

    return (
        <div className='a'>
            <div className='a-left'>
                <div className='a-card bg'></div>
                <div className='a-card'>
                    <img src='https://i.postimg.cc/9fWh4c43/20201106-205524-resized-2.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500' alt='' className='a-img' />
                </div>
            </div>
            <div className='a-right'>
                <h1 className='a-title'>About Me</h1>
                <p className='a-sub'>{aboutSubText}</p>
                <p className='a-desc'>{aboutDescText}</p>
                {/* Certifications */}
                <div className='a-award'>
                    <img src={TestDome} alt='' className='a-award-img' />
                    <div className='a-award-texts'>
                        <h4 className='a-award-title'>PHP and Laravel</h4>
                        <p className='a-award-desc'>Issued Oct 2021</p>
                        <p className='a-award-desc'>Credential ID 11429bce45874664a14287264303422c</p>
                    </div>
                </div>
                <div className='a-award'>
                    <img src={Bdjobs} alt='' className='a-award-img' />
                    <div className='a-award-texts'>
                        <h4 className='a-award-title'>Bdjobs-AMCAT Employability Certificate</h4>
                        <p className='a-award-desc'>Test: Computer Science, Score: 1860</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
