export const projects = [
    {
        id: 1,
        img: 'https://i.postimg.cc/fRHyLQ12/Calomama-Online-Laravel-Vue-js.png',
        link: 'https://dev-health-guidance.lconlinecounseling.com'
    },
    {
        id: 2,
        img: 'https://i.postimg.cc/vZ8DbZ2x/Budget-App-React.png',
        link: 'https://budget-app.robiparvez.com'
    },
    {
        id: 3,
        img: 'https://i.postimg.cc/8Cj5fnS1/Survey-Management-System-Laravel-Vue-js.png',
        link: 'https://www.survey-management-system.robiparvez.com'
    },
    {
        id: 4,
        img: 'https://i.postimg.cc/CKXKGHhb/Google-Maps-Clone-Javascript.png',
        link: 'https://robiparvez.github.io/google-maps-clone'
    },
    {
        id: 5,
        img: 'https://i.postimg.cc/9M9QJR0K/Covid-19-Tracker-React.png',
        link: 'https://robiparvez.github.io/covid19-tracker'
    },
    {
        id: 6,
        img: 'https://i.postimg.cc/sD41khmR/Pay-Money-Secure-Online-Payment-Gateway-Laravel-Vue-js-Node-js.png',
        link: 'https://paymoney.techvill.org'
    }
];

export const emailJsConfig = {
    serviceId: 'service_xnexqb3',
    templateId: 'template_qb8ts5r',
    userId: 'user_b8h0f1aQWrhd7FWESkQNe'
};
